// Colors

// THEME

/* SCSS HEX */
$medium-turquoise: #55dde0ff;
$queen-blue: #33658aff;
$charcoal: #2f4858ff;
$honey-yellow: #f6ae2dff;
$safety-orange-blaze-orange: #f26419ff;

/* SCSS HSL */
$medium-turquoise: hsla(181, 69%, 61%, 1);
$queen-blue: hsla(206, 46%, 37%, 1);
$charcoal: hsla(203, 30%, 26%, 1);
$honey-yellow: hsla(39, 92%, 57%, 1);
$safety-orange-blaze-orange: hsla(21, 89%, 52%, 1);

/* SCSS RGB */
$medium-turquoise: rgba(85, 221, 224, 1);
$queen-blue: rgba(51, 101, 138, 1);
$charcoal: rgba(47, 72, 88, 1);
$honey-yellow: rgba(246, 174, 45, 1);
$safety-orange-blaze-orange: rgba(242, 100, 25, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-right: linear-gradient(90deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-bottom: linear-gradient(180deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-left: linear-gradient(270deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-top-right: linear-gradient(45deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-bottom-right: linear-gradient(135deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-top-left: linear-gradient(225deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-bottom-left: linear-gradient(315deg, #55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);
$gradient-radial: radial-gradient(#55dde0ff, #33658aff, #2f4858ff, #f6ae2dff, #f26419ff);

//  END THEME

// Gray
$black: #222;
$darkGray: #666;
$orange: #F29F58;
$bkg-white: #f5f5fd;
$white: #f1f5ff;

$red: #b7410e;

$dark-blue: #1c2541;
$blue: #0075a2;
$blue-2: #0075a2;
$lite-blue: #2e64a7;

$lite-green: #5ad3d1;
$green: #84C0C6;
$dark-green: #3aa4a2;
// darker
$dark-cobalt: rgba(42, 91, 152, 1);
$dark-verdigris: rgba(64, 161, 183, 1);
$dark-laurelGreen: rgba(164, 188, 163, 1);
$dark-moonstoneBlue: rgba(120, 175, 180, 1);
$dark-slateGray: rgba(119, 126, 136, 1);

$cobalt: rgba(46, 100, 167, 1);
$verdigris: rgba(70, 177, 201, 1);
$laurelGreen: rgba(180, 206, 179, 1);
$moonstoneBlue: rgba(132, 192, 198, 1);
$slateGray: rgba(130, 138, 149, 1);

// lighter
$lite-cobalt: rgba(65, 114, 175, 1);
$lite-verdigris: rgba(86, 184, 205, 1);
$lite-laurelGreen: rgba(186, 210, 185, 1);
$lite-moonstoneBlue: rgba(143, 197, 203, 1);
$lite-slateGray: rgba(141, 148, 158, 1);


// SHADES
$lite-green-100: rgba(111, 255, 233, 1);
$lite-green-90: rgba(111, 255, 233, 0.9);
$lite-green-80: rgba(111, 255, 233, 0.8);
$lite-green-70: rgba(111, 255, 233, 0.7);
$lite-green-60: rgba(111, 255, 233, 0.6);
$lite-green-50: rgba(111, 255, 233, 0.5);
$lite-green-40: rgba(111, 255, 233, 0.4);
$lite-green-30: rgba(111, 255, 233, 0.3);
$lite-green-20: rgba(111, 255, 233, 0.2);
$lite-green-10: rgba(111, 255, 233, 0.1);

$green-100: rgba(91, 192, 190, 1);
$green-90: rgba(91, 192, 190, 0.9);
$green-80: rgba(91, 192, 190, 0.8);
$green-70: rgba(91, 192, 190, 0.7);
$green-60: rgba(91, 192, 190, 0.6);
$green-50: rgba(91, 192, 190, 0.5);
$green-40: rgba(91, 192, 190, 0.4);
$green-30: rgba(91, 192, 190, 0.3);
$green-20: rgba(91, 192, 190, 0.2);
$green-10: rgba(91, 192, 190, 0.1);

$gray-100: rgba(117, 125, 133, 1);
$gray-90: rgba(117, 125, 133, 0.9);
$gray-80: rgba(117, 125, 133, 0.8);
$gray-70: rgba(117, 125, 133, 0.7);
$gray-60: rgba(117, 125, 133, 0.6);
$gray-50: rgba(117, 125, 133, 0.5);
$gray-40: rgba(117, 125, 133, 0.4);
$gray-30: rgba(117, 125, 133, 0.3);
$gray-20: rgba(117, 125, 133, 0.2);
$gray-10: rgba(117, 125, 133, 0.1);

// Font stacks

$sans-serif: 'Lato', sans-serif;
$serif: 'Saffran ExtraBold', serif;


// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}
